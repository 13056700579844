import React from "react";

export default function LoadSingle() {
  return (
    <div className="Placeholder single">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="image small"></div>
      <div className="cont">
        <p></p>
        <p></p>
        <p></p>
      </div>
      <div className="image"></div>
      <div className="cont">
        <p></p>
      </div>
      <div className="image"></div>
      <div className="cont">
        <p></p>
      </div>
      <div className="image"></div>
      <div className="cont">
        <p></p>
      </div>
      <div className="image"></div>
      <div className="cont">
        <p></p>
      </div>
    </div>
  );
}
