import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <h1>
        404 - Page Not Found lorem<br></br>
        lorem<br></br>
        lorem<br></br>
        lorem<br></br>
        lorem<br></br>
        lorem<br></br>
        lorem<br></br>
      </h1>
    </div>
  );
};

export default PageNotFound;
